import { useReCaptcha } from 'next-recaptcha-v3'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

// https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect


// https://usehooks-ts.com/react-hook/use-is-mounted
export function useIsMounted() {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return useCallback(() => isMounted.current, [])
}


// https://usehooks-ts.com/react-hook/use-interval
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

export function useCheckEmptyGlobalState(props) {
  const router = useRouter();

  useEffect(() => {
    if (!props.globalState && !router.asPath.includes('__fr=1')) {
      if (router.asPath.includes('?')) {
        router.push(`${router.asPath}&__fr=1`, undefined, {
          shallow: true,
        });
      } else {
        router.push(`${router.asPath}?__fr=1`, undefined, {
          shallow: true,
        });
      }
  
      router.reload();
    }
  })
}

/**
 * Calls recaptcha apis to get token and verify, action is a string
 * @param action 
 * @returns response from google recaptcha api
 */
 export const useVerifyRecaptcha =  () => {
  const { executeRecaptcha } = useReCaptcha();

  const verifyRecaptcha = async (action) => {
    const token = await executeRecaptcha(action);
    const recaptchaBody = { 
      token: token,
      action: action
    }

    const verifyToken = await fetch("/api/recaptcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(recaptchaBody)
    });
    
    return verifyToken;
  }

  return verifyRecaptcha;
}