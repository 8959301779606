import { storyblokEditable } from "@storyblok/react";
import { Box, Button, Center, Collapse, Flex, FormControl, FormErrorMessage, FormLabel, GridItem, Input, Select, SimpleGrid, Text, useDisclosure, VisuallyHidden,} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ContactFormLabel from "./ContactFormLabel";
import ContactTextField from "./ContactTextField";
import ContactTextAreaField from "./ContactTextAreaField";
import ContactFormLocationCheckboxes from "./ContactFormLocationCheckboxes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AlertModal from "../AlertModal";
import { event } from "nextjs-google-analytics";
import IndividualContactModal from "./IndividualContactModal";
import { useRouter } from 'next/navigation';
import { useVerifyRecaptcha } from "../../../utils/hooks";

export interface ContactFormProps {
  name: string;
  phone: string;
  email: string;
  size: string;
  organization: string;
  comments: string;
  location: string[];
  type: string;
  nameField: string;
  emailField: string;
}

export type ContactFormType = "disaster-contact" | "fmsc" | "ppm-365" | "special-offer" | "stuff" | "gls" | "sponsorship-sunday"


const ContactForm = ({ blok, globalState, setGlobalState }) => {
  const { isOpen, onToggle } = useDisclosure();
  const router = useRouter();
  
  const [modalData, setModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: 'We have received your information, and someone will be following up with you soon. Thanks!'
  });
  
  const confirmModal = useDisclosure();
  const individualModal = useDisclosure();
  
  const verifyRecaptcha = useVerifyRecaptcha();

  const [individualFormValues, setIndividualFormValues] = useState<ContactFormProps>({
    name: '',
    phone: '',
    email: '',
    size: '',
    type: blok.contactFormType ?? 'main-contact',
    organization: '',
    comments: '',
    location: [],
    nameField: '',
    emailField: ''
  })

  const { register, reset, handleSubmit, getValues, formState, setValue } = useForm<ContactFormProps>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      size: '',
      type: blok.contactFormType || 'main-contact',
      organization: '',
      comments: '',
      location: []
    }
  });

  useEffect(() => {
    setValue('email', globalState.user?.email ?? '');
    setValue('name', globalState.user?.name ?? '');
  }, [globalState.user, setValue])

  const submitContactForm = async () => {
    const values = getValues();

    const currentPage = window ? window.location.pathname + window.location.search + window.location.hash : '';

    const hasHoneypotData: boolean = (!! values && (values.name?.length > 0 || values.email?.length > 0));

    if (!hasHoneypotData) {
      values.name = values.nameField;
      values.email = values.emailField;
    }
    
    const submitData = {
      ...values,
      location: values.location.join('; '),
      type: blok.contactFormType || 'main-contact',
      analyticsInfo: {
        currentPage: currentPage,
        source: '-',
        medium: '-',
        campaign: '-',
        term: '-',
        content: '-',
        numVisits: 0
      }
    }

    const verifyToken = await verifyRecaptcha('contact_submit');

    if (verifyToken.ok && !hasHoneypotData) {
      // if individual form, redirect to individual and small groups page
      if (values.size === 'Individual' && (!submitData.type || submitData.type === 'main-contact')) {
        router.push('/small-groups-and-individuals');
      } else if (values.size === 'Individual') {
        setIndividualFormValues(values);
        individualModal.onOpen();
      } else {
        try {
          const sendContactForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/contact`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
          })
    
          if (sendContactForm.ok) {
            setModalData({
              icon: ['far', 'circle-check'] as IconProp,
              iconColor: 'brand.green.600',
              title: 'Got It!',
              content: 'We have received your information, and someone will be following up with you soon. Thanks!'
            });
    
            confirmModal.onOpen();
    
            reset();
          } else {
            console.error(sendContactForm)
            throw new Error('Failed to send contact form')
          }
          
          setModalData({
            icon: ['far', 'circle-check'] as IconProp,
            iconColor: 'brand.green.600',
            title: 'Got It!',
            content: 'We have received your information, and someone will be following up with you soon. Thanks!'
          });
    
          confirmModal.onOpen();
    
          reset();
    
          if (submitData.size === 'Group') {
            event('group_lead', {
              category: submitData.type,
              label: submitData.size
            });
          } else {
            event('individual_lead', {
              category: submitData.type,
              label: submitData.size
            });
          }
    
        } catch (error) {
          console.error(error);
          setModalData({
            icon: ['fas', 'xmark'] as IconProp,
            iconColor: 'red.400',
            title: 'Oops!',
            content: 'Sorry, but there was an error submitting your information. Please try again later.'
          });
    
          confirmModal.onOpen();
        }
      }
    }


  }

  return (
    <>
      <IndividualContactModal
        onClose={individualModal.onClose}
        isOpen={individualModal.isOpen}
        contactFormValues={individualFormValues}
        globalState={globalState}
        setGlobalState={setGlobalState}
        contactFormType={blok.contactFormType ?? 'main-contact'}
        resetMainForm={reset}
      />

      <AlertModal
        onClose={confirmModal.onClose}
        isOpen={confirmModal.isOpen}
        iconColor={modalData.iconColor}
        icon={modalData.icon}
        title={modalData.title}
        content={modalData.content}
      />
      
      <form onSubmit={handleSubmit(submitContactForm)} style={{width: '100%'}}>
        <Flex
          align="center"
          justify="center"
          className="contact-form"
          w="full"
          {...storyblokEditable(blok)}
        >
          <Box w="full">
            <Flex
              direction={{ base: 'column', lg: blok.horizontalLayout ? 'row' : 'column' }}
              align={{ base: 'flex-start', lg: blok.horizontalLayout ? 'center' : 'flex-start' }}
              gap={{ base: 0, lg: blok.horizontalLayout ? 5 : 0 }}
              py={blok.horizontalLayout ? 5 : 0}
              w="full"
            >
              {blok.horizontalLayout && blok.horizontalCallToAction &&
                <Text
                  whiteSpace={'nowrap'}
                  mb={{ base: 5, lg: 0 }}
                  fontSize={{ base: '2xl', md: '3xl' }}
                  fontWeight="bold"
                  color="brand.darkgray.700"
                  textAlign={'center'}
                  width="full"
                >
                  {blok.horizontalCallToAction}
                </Text>
              }
              {blok.contactFormType !== "sponsorship-sunday"  && <FormControl
                borderRadius={'lg'}
                mb={{ base: 4, lg: blok.horizontalLayout ? 0 : 4 }}
                isInvalid={!!formState.errors.size}
                isDisabled={formState.isSubmitting}
              >
                <SimpleGrid columns={{ base: 1, md: blok.showLabels ? 4 : 3 }}>
                  {
                    blok.showLabels &&
                    <ContactFormLabel
                      label="Your Role"
                      forField={'size'}
                      color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                    />
                  }
                  <GridItem colSpan={3}>
                    {
                      !blok.showLabels &&
                      <VisuallyHidden>
                        <FormLabel htmlFor="role">Your Role</FormLabel>
                      </VisuallyHidden>
                    }
                    <Select
                      color="whiteAlpha.800"
                      name="size"
                      size={blok.fieldSize}
                      layerStyle={blok.colorScheme === 'light' ? 'lightForm' : 'darkForm'}
                      sx={{
                        _focusVisible: {
                          borderColor: 'brand.orange.600',
                          boxShadow: '0 0 0 1px var(--chakra-colors-brand-orange-600)',
                        }
                      }}
                      {...register('size', {required: true})}
                    >
                      <option value="" style={{ color: 'black' }}>I am...</option>
                      <option value="Group" style={{ color: 'black' }}>A Group Leader</option>
                      <option value="Individual" style={{ color: 'black' }}>An Individual</option>
                    </Select>
                    <FormErrorMessage>Please select your role.</FormErrorMessage>
                  </GridItem>
                </SimpleGrid>
              </FormControl>
}
              <ContactTextField
                showLabels={blok.showLabels}
                colorScheme={blok.colorScheme}
                fieldName={'nameField'}
                fieldType={'text'}
                fieldLabel={'Your Name'}
                isRequired={true}
                horizontalLayout={blok.horizontalLayout}
                fieldSize={blok.fieldSize}
                register={register}
                errors={formState.errors}
                submitting={formState.isSubmitting}
              />

              <ContactTextField
                showLabels={blok.showLabels}
                colorScheme={blok.colorScheme}
                fieldName={'phone'}
                fieldType={'tel'}
                fieldLabel={'Phone Number'}
                isRequired={true}
                horizontalLayout={blok.horizontalLayout}
                fieldSize={blok.fieldSize}
                register={register}
                errors={formState.errors}
                submitting={formState.isSubmitting}
              />

              <ContactTextField
                showLabels={blok.showLabels}
                colorScheme={blok.colorScheme}
                fieldName={'emailField'}
                fieldType={'email'}
                fieldLabel={'Email Address'}
                isRequired={true}
                horizontalLayout={blok.horizontalLayout}
                fieldSize={blok.fieldSize}
                register={register}
                errors={formState.errors}
                submitting={formState.isSubmitting}
              />

              <Input 
                name={'name'}
                type={'text'}
                className={'honey'}
                variant="outline"
                placeholder={'Name'}
                autoComplete={'nope'}
                tabIndex={-1}
                {...register('name', { required: false })}
              />

              <Input 
                name={'email'}
                type={'email'}
                className={'honey'}
                variant="outline"
                placeholder={'Email'}
                autoComplete={'nope'}
                tabIndex={-1}
                {...register('email', { required: false })}
              />

              {
                blok.showOptionalToggle &&
                <>
                  <Box w="full">
                    <SimpleGrid columns={{ base: 1, md: blok.showLabels ? 4 : 3 }}>
                      {
                        blok.showLabels &&
                        <GridItem colSpan={1}>&nbsp;</GridItem>
                      }
                      <GridItem colSpan={3}>
                        {
                          <Button
                            variant="link"
                            width="full"
                            size="sm"
                            colorScheme={blok.colorScheme === 'dark' ? 'brand.green' : 'brand.darkgray'}
                            onClick={onToggle}
                            mb="4"
                          >
                            {isOpen ? 'Hide' : 'Show'} Optional Fields
                          </Button>
                        }
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                  <Collapse in={isOpen} style={{ width: '100%' }}>
                    <Flex direction="column">
                      <ContactTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'organization'}
                        fieldType={'text'}
                        fieldLabel={'Organization'}
                        isRequired={false}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />

                      <ContactTextAreaField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'comments'}
                        fieldLabel={'Comments'}
                        isRequired={false}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />

                      <FormControl borderRadius={'lg'} mb="4">
                        <SimpleGrid columns={{ base: 1, md: blok.showLabels ? 4 : 3 }}>
                          {
                            blok.showLabels &&
                            <GridItem colSpan={1} pt="3">
                              <ContactFormLabel
                                forField="location"
                                label={'Locations of Interest'}
                                color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                              />
                            </GridItem>
                          }
                          <GridItem colSpan={3}>
                            <Center flexDirection={'column'}>
                              {
                                !blok.showLabels &&
                                <FormLabel
                                  color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                                  fontWeight={'bolder'}
                                >
                                  Locations of Interest
                                </FormLabel>
                              }

                              <ContactFormLocationCheckboxes
                                heading="DOMESTIC"
                                colorScheme={blok.colorScheme}
                                locations={globalState.locations.filter(location => location.rootsData.domestic === 1)}
                                register={register}
                                submitting={formState.isSubmitting}
                              />

                              <ContactFormLocationCheckboxes
                                heading="INTERNATIONAL"
                                colorScheme={blok.colorScheme}
                                locations={globalState.locations.filter(location => location.rootsData.domestic === 0)}
                                register={register}
                                submitting={formState.isSubmitting}
                              />
                            </Center>
                          </GridItem>
                        </SimpleGrid>
                      </FormControl>

                    </Flex>
                  </Collapse>
                </>
              }
              <Box w="full">
                <SimpleGrid columns={{ base: 1, md: blok.showLabels ? 4 : 3 }}>
                  {
                    blok.showLabels &&
                    <GridItem colSpan={1}>&nbsp;</GridItem>
                  }

                  <GridItem colSpan={3}>
                    <Button
                      type="submit"
                      width="full"
                      colorScheme={blok.colorScheme === 'light' ? 'brand.orange' : 'brand.orange'}
                      variant={blok.colorScheme === 'light' ? 'solid' : 'solid'}
                      leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                      disabled={formState.isSubmitting}
                    >
                      {blok.submitText}
                    </Button>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </form>
    </>
  );
}

export default ContactForm;